/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {
  combo,
  stepWithFallback,
  setCreativesGroup,
  setCreativesHiddenAfterAt,
  setCreativesVisibleAfterAt,
  whenSplit,
  whenSplitInRange,
  getCreativesInRandomOrder,
  skipOnMultifaceError,
  getCreativesInRandomOrderWithWeights
} from "./helpers";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";

function getGroups() {
  let list = [
    groups.g1,
    groups.g2,
    groups.g3,
    groups.g4,
  ];

  return list;
}

const faceTemplates = [
  {id: 7092, weight: 10},
];

const positionsTemplatesPairs = [
  {canvasId: 8206, maskId: 8207, gender: "none", weight: 100},
];

const promptTemplates = [
  {id: 8296},
  {id: 8297},
  {id: 8298},
  {id: 8301},
  {id: 8302},
  {id: 8303},
  {id: 8314},
  {id: 8332},
  {id: 8333},
  {id: 8335},
  {id: 8340},
  {id: 8342},
  {id: 8361},
  {id: 8364},
  {id: 8373},
  {id: 8378},
  {id: 8289},
  {id: 8382},
  {id: 8384},
  {id: 8385},
  {id: 8400},
  {id: 8407},
  {id: 8408},
  {id: 8412},
  {id: 8414},
  {id: 8418},
  {id: 8426},
  {id: 8427},
  {id: 8429},
  {id: 8436},
  {id: 8439},
  {id: 8440},
  {id: 8446},
  {id: 8447},
  {id: 8448},
  {id: 8449},
  {id: 8453},
  {id: 8454},
  {id: 8455},
  {id: 8507},
  {id: 8510},
  {id: 8516},
  {id: 8518},
  {id: 8519},
  {id: 8522},
  {id: 8534, canvasId: 8206, maskId: 8207},
  {id: 8535, canvasId: 8206, maskId: 8207},
  {id: 8536, canvasId: 8206, maskId: 8207},
  {id: 8537, canvasId: 8206, maskId: 8207},
  {id: 8538, canvasId: 8206, maskId: 8207},
  {id: 8538, canvasId: 8206, maskId: 8207},
  {id: 8539, canvasId: 8206, maskId: 8207},
  {id: 8540, canvasId: 8206, maskId: 8207},
  {id: 8541, canvasId: 8206, maskId: 8207},
  {id: 8542, canvasId: 8206, maskId: 8207},
  {id: 8543, canvasId: 8206, maskId: 8207},
  {id: 8544, canvasId: 8206, maskId: 8207},
  {id: 8545, canvasId: 8206, maskId: 8207},
  {id: 8546, canvasId: 8206, maskId: 8207},
  {id: 8547, canvasId: 8206, maskId: 8207},
];

function getRandomIndexWithWeight(array) {
  return array.randomIndex();
  // if (array.isEmpty()) {
  //   return -1;
  // }
  //
  // const sum = array.reduce((t, item) => t + item.weight, 0);
  // let r = Math.floor(Math.random() * (sum + 1));
  //
  // return array.findIndex((item) => {
  //   r -= item.weight;
  //   return r <= 0;
  // });
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function() {
    let listOfAll = [];

    const items = faceTemplates.map((preItem) => {
      return {
        ...preItem,
        children: positionsTemplatesPairs.map((positionItem) => {
          return {
            ...positionItem,
            children: promptTemplates.slice(),
          };
        }),
      };
    });

    while (true) {
      const faceIndex = getRandomIndexWithWeight(items)
      if (faceIndex === -1) {
        break;
      }

      const positionIndex = getRandomIndexWithWeight(items[faceIndex].children)
      if (positionIndex === -1) {
        items.splice(faceIndex, 1);
        continue;
      }

      const promptIndex = getRandomIndexWithWeight(items[faceIndex].children[positionIndex].children)
      if (promptIndex === -1) {
        items[faceIndex].children.splice(positionIndex, 1);
        continue;
      }

      const face = items[faceIndex];
      const position = items[faceIndex].children[positionIndex];
      const prompt = items[faceIndex].children[positionIndex].children[promptIndex];

      const combo = new CreativeConfig(
        groups.g1,
        `${face.id}_[${position.canvasId}_${position.maskId}]_${prompt.id}`,
        handlersNames.COMBO
      );

      // const isManFirst = position.gender.indexOf("man") === 0;
      // const imagesOrder = [
      //   {src: "#0"},
      //   {src: "#1"}
      // ];

      combo.setExtra("t_prompt_id", prompt.id);
      combo.setExtra("t_face_id", face.id);
      combo.setExtra("t_canvas_id", position.canvasId);
      combo.setExtra("t_mask_id", position.maskId);
      //combo.setExtra("t_gender", position.gender);

      combo.setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: "gender_classifier", image: {src: "@0"}},
        {id: 6472, images: [{src: "@0"}]},
	{id: 2275},
        //{id: face.id, images: [{src: "@1"}]},
        {
          id: prompt.id,
          //images: isManFirst ? imagesOrder : imagesOrder.reverse(),
          templateParams: {
            gender: "#0",
            canvas_template_name: position.canvasId,
            mask_template_name: position.maskId,
          },
          setAsFile: "raw",
        },
      ]);

      listOfAll.push(combo);

      items[faceIndex].children[positionIndex].children.splice(promptIndex, 1);
    }

    return listOfAll;
  },
};
