import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import {debounce, pwAssetUrl} from "../utils/etc";
import AppContext from "../contexts/AppContext";
import {preloadNativeAds} from "../utils/native-ads";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {webviewAnalyticsEvent} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import processingManager from "../photolab/ProcessingManager";
import FileChooseButton from "../components/FileChooseButton";

export default class IndexPage extends React.Component {

  state = {};

  componentDidMount() {
    if (clientStorage.getShouldRedirectToResult()) {
      clientStorage.setShouldRedirectToResult(false);

      if (processingManager.hasStoredProcessing()) {
        this.props.history.replace(routes.RESULT);
        return;
      }
    }

    window.webviewEventsListeners.tabSelected.subscribe((millis) => {
      if (millis > 0) {
        debounce("IndexPage.handleWebviewTabSelected", 300, () => {
          logEvent(userEvents.PAGE_INDEX);
        });
      }
    }, true);

    if (window.clientConfig.isWeb) {
      logEvent(userEvents.PAGE_INDEX);
    }

    this.context.hideLoader();
  }

  handleFileSelected = (file) => {
    preloadNativeAds();

    this.context.showLoader(false, () => {
      this.props.history.replace(routes.UPLOAD, {file: file[0]});
    });
  };

  renderDefault = () => {
    const classNames = ["main-page"];

    return <main className={classNames.join(" ")}>
      <div className="container">
        <div className="content-container">

          <div className="content-video">
            <video
              className="main-video"
              playsInline
              autoPlay
              loop
              muted>
              <source type="video/mp4" src={pwAssetUrl(`duet/duet_video_v2.mp4`)} />
            </video>
          </div>

          <h1 dangerouslySetInnerHTML={{__html: i18n.t("index_title")}} />
          <p dangerouslySetInnerHTML={{__html: i18n.t("index_subtitle")}} />

          <FileChooseButton
            className="btn-upload-foto"
            onFilesSelected={this.handleFileSelected}
            children={i18n.t("upload_choose_photo_button")} />
        </div>

        <div className="footer">
          <IconHearts />
          <p dangerouslySetInnerHTML={{__html: i18n.t("footer_text")}} />
        </div>
      </div>
    </main>;
  }

  render() {
    return this.renderDefault();
  }
}

function IconHearts() {
  return <svg viewBox="0 0 56.0 30.0">
    <defs>
      <clipPath id="i0">
        <path d="M26.5388001,4.00167104 L26.635,4.201 L26.7243001,4.40198104 C28.1212001,7.71431104 26.9233001,11.638101 25.8086001,14.439301 C24.2629001,18.324201 21.3807001,22.780901 19.5647001,25.037301 C16.7080001,24.588001 11.5813001,23.225001 7.82680007,21.387201 L7.30017863,21.123908 C4.62532425,19.7585737 1.38553701,17.6503823 0.338191069,14.357601 C-0.794278931,10.804601 0.929942069,6.60317104 5.32209007,5.14315104 C7.66638488,4.36568363 10.0288455,5.19627093 11.3393208,5.83356715 L11.4858001,5.90614104 C12.0489001,6.19038104 12.3821001,6.41939104 12.3821001,6.41939104 L12.4406724,6.20357782 C12.4909495,6.03097919 12.5754001,5.76409215 12.7009001,5.43688104 L12.8433504,5.0837296 C13.4198306,3.72198403 14.6030334,1.67856529 16.7539001,0.727332042 C20.9032001,-1.10868896 24.9201001,0.732257042 26.5388001,4.00167104 Z M17.1583664,1.64188494 C15.5110838,2.37040522 14.3501629,3.92935685 13.6345803,5.79498889 L13.5148032,6.12378537 C13.4796932,6.22578647 13.4493583,6.31975174 13.4236766,6.40436678 L13.3525424,6.66072401 L13.0044309,8.06054204 L11.8156804,7.24350801 C11.6841242,7.15308879 11.4146482,6.99040375 11.0352293,6.79888173 C9.25270445,5.89923229 7.34623813,5.52541689 5.63753423,6.09209518 C1.99368654,7.30336823 0.250513946,10.7896199 1.29114671,14.0544913 C2.12335061,16.6708812 4.50994567,18.64764 8.26644779,20.4890307 C11.1984221,21.9242105 15.4685327,23.247205 18.7273322,23.8749872 L19.145,23.952 L19.3259254,23.7169331 C21.2470221,21.1761091 23.4464626,17.4962004 24.7060481,14.4936895 L24.8794638,14.0695637 C26.3919787,10.2686693 26.8466344,7.26550009 25.8025862,4.78985158 L25.6867592,4.53638456 L25.6426247,4.44537107 C24.1532646,1.43719493 20.598439,0.119689517 17.1583664,1.64188494 Z">
        </path>
      </clipPath>
      <clipPath id="i1">
        <path d="M0.0615883563,4.11140641 C0.454388356,1.62520641 2.76008836,-0.386093587 5.85148836,0.0631064127 C8.32038836,0.420506413 9.72328836,2.96780641 9.72328836,2.96780641 L9.74781292,2.93942066 C9.97498791,2.68082552 11.7562484,0.745173079 14.0796884,0.859506413 C17.2016884,1.01260641 19.0788884,3.42730641 18.9870884,5.94410641 C18.9003884,8.42510641 16.9465884,10.4875064 15.3957884,11.8658064 C13.2889884,13.7394064 10.2027884,15.5567064 8.42758836,16.3684064 C6.84108836,15.2351064 4.16288836,12.8562064 2.45398836,10.6151064 C1.19398836,8.96620641 -0.326111644,6.56690641 0.0615883563,4.11140641 Z"></path>
      </clipPath>
    </defs>
    <g transform="translate(-6.861402113145232e-08 -1.0419116804882833e-06)">
      <g clipPath="url(#i0)">
        <polygon points="0,0 27.3560015,0 27.3560015,25.037301 0,25.037301 0,0" stroke="none" fill="#F8D34F"></polygon>
      </g>
      <g transform="translate(37.00001171227129 12.999994629198897)">
        <g clipPath="url(#i1)">
          <polygon points="-3.531897e-15,-6.9388939e-16 18.9903076,-6.9388939e-16 18.9903076,16.3684064 -3.531897e-15,16.3684064 -3.531897e-15,-6.9388939e-16" stroke="none" fill="#FD77AB"></polygon>
        </g>
      </g>
    </g>
  </svg>;
}

IndexPage.contextType = AppContext;
