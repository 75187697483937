module.exports = {
    "index_title": "Love fantasies by AI",
    "index_subtitle": "Explore romantic fantasies and moments made for your dream couple. Exclusively by AI.",
    "index_start_button": "Start",

    "upload_title": 'Upload your photos',
    "upload_notice_1": "2 portrait photos",
    "upload_notice_2": "straight face position",
    "upload_notice_text": "Please choose two portrait photos (one photo per person). For best results, upload photos with faces looking straight at the camera.",
    "upload_choose_photo_button": "Choose photo",
    "upload_footer": "AI may generate artefacts, inaccuracies and defects in result images - <span>it's out of our control.</span>",
    "upload_male": "His photo",
    "upload_female": "Her photo",

    "result_title": "Results",
    "result_notice": "Your artwork is amazing! <span class=\"text-decoration\">Share your love!</span>",

    "footer_text": "<span class=\"text-white\">New.</span><span>Fantastic.</span><span class=\"text-pink\">Lovely</span>",

    "change_photo": "Change photo",
    "another_go": "ANOTHER GO",
    "another_go__wait": "Wait",
    "try_another_photo": "Try another photo",
    "reload": "reload",
    "retry": "Retry",
    "try_again": "Try again",

    "download": "Download",
    "save_and_share": "Save & Share",

    "error__default_message": "Something went wrong. Try again.",
    "error_title": "Something went wrong!",
    "error__api_code_1": "Internal error",
    "error__api_code_2": "Incorrect params",
    "error__api_code_3": "Processing failure",
    "error__api_code_401": "Unauthorized request",
    "error__api_code_404": "Not found",
    "error__api_code_410": "The requested content is deleted",
    "error__api_code_415": "Sorry, this file format is not supported. Please upload an image file (JPEG or PNG).",

    "error_message_duplicate": "Ooops! You’ve chosen two same photos. Please, change one of them.",

    "watermark_screen__title": "",
    "draggable_watermark_tooltip": "<span>Tap&Drag</span> to move <br>& delete watermark",
    "watermark_removal_modal__title": "Yep, you can remove the watermark, but…",
    "watermark_removal_modal__text1": "We’d like you to keep it and let your friends know about our app. Don’t feel like keeping it? Spread the hash-word about {{app_name}}.",
    "watermark_removal_modal__text2": "The entire amount of money raised goes to a charity fund!",
    "watermark_removal_modal__notice": "<span class=\"text-bold\">TIP:</span> you can just drag the watermark to the position you want.",
    "watermark_removal_modal__choice": "Your choice?",
    "watermark_removal_modal__confirm": "Remove watermark",
    "watermark_removal_modal__cancel": "Spread the good!",
    "watermark_removal_modal__close": "Close",

    "processing_text_1": "These two were made to be together 😍",
    "processing_text_2": "Where is my love potion...",
    "processing_text_3": "Almost ready! Making some magic...",
    "processing_text_4": "Ready to see them together?",
    "processing_text_5": "AI. Connecting people",

    "button_subscribe_with_price": "Continue",
    "button_subscribe_sub": "*then {{price}}/week",
    "subscribe_subscription_terms": "Renews automatically. Cancel anytime. No commitment.",
    "button_purchase_with_price": "Continue",
};

