import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import AppContext from "../contexts/AppContext";
import {preloadNativeAds} from "../utils/native-ads";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {fileToJson, webviewAnalyticsEvent} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import processingManager from "../photolab/ProcessingManager";
import {ApiResponseError} from "../utils/api";
import {promisifyImage} from "../utils/image";
import * as api from "../utils/api";
import PhotolabTaskBuilder from "../photolab/PhotolabTaskBuilder";
import PhotolabTaskImageUrl from "../photolab/PhotolabTaskImageUrl";
import PhotolabTaskCollageMethod from "../photolab/PhotolabTaskCollageMethod";
import {photolabTask} from "../photolab/api";
import {generatePath} from "react-router";
import ErrorView from "../components/ErrorView";

const IMAGE_MAX_SIDE_SIZE = 1200;

export default class UploadPage extends React.Component {

  state = {
    error: null,
    file: null,
  };

  componentDidMount() {
    const loadedUrl = new URL(window.location.href);
    const locationState = this.props.location.state || {};

    const fileUrl = loadedUrl.searchParams.has("file_url")
      ? decodeURIComponent(loadedUrl.searchParams.get("file_url"))
      : null;

    const file = fileUrl || locationState.file;

    if (file) {
      this.createFile(file, fileUrl || window.clientConfig.isWebview);
    } else {
      this.props.history.replace(generatePath(routes.INDEX));
    }
  }

  createFile = (file, isUploaded = false) => {
    if (isUploaded) {
      this.handleFileUploaded(fileToJson(file));
      return;
    }

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
    ];

    if (!allowedTypes.includes(file.type.toLowerCase())) {
      this.setState({
        error: new ApiResponseError({
          error_code: 415,
          error_message: i18n.t("error__api_code_415"),
        }),
      }, this.context.hideLoader);
      return;
    }

    this.fileUpload(file)
      .then((file) => {
        this.handleFileUploaded(file);
      })
      .catch((error) => {
        this.setState({error}, this.context.hideLoader);
      });
  };

  fileUpload = (file) => {
    return promisifyFileReader(file)
      .then(promisifyImage)
      .then(prepareImage)
      .then((result) => {
        return api.tempImagesUploadFile(result, file.type.replace("image/"));
      })
      .then((result)=> {
        return fileToJson(result);
      })
      .catch((error) => {
        throw error;
      });
  };

  handleFileSelected = (file) => {
    preloadNativeAds();

    this.context.showLoader(false);

    this.setState({
      error: null,
      file: file[0],
    }, () => {
      this.createFile(file, window.clientConfig.isWebview);
    });
  };

  handleFileUploaded = (file) => {
    const taskConfig = new PhotolabTaskBuilder();
    taskConfig.addImage(new PhotolabTaskImageUrl(file.url));
    taskConfig.addMethod(new PhotolabTaskCollageMethod(4021));

    photolabTask(taskConfig.build())
      .then((taskResult) => {
        this.setState({file}, this.startProcessing);

        return file;
      }).catch((error) => {
        this.setState({error}, this.context.hideLoader);

        throw error;
      });
  };

  startProcessing = () => {
    processingManager.clear();

    this.context.setLoaderImages([
      this.state.file.url,
    ], () => {
      this.context.showLoader(true, () => {
        this.props.history.replace({
          pathname: generatePath(routes.PROCESSING),
          state: {files: [this.state.file]},
        });
      });
    });
  };

  render() {
    if (this.state.error) {
      return <ErrorView
        error={this.state.error}
        onButtonClick={() => this.setState({error: null})}
      />;
    }

    return <React.Fragment />;
  }
}

UploadPage.contextType = AppContext;

function promisifyFileReader(input) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => resolve(e.target.result);
    fileReader.onerror = (e) => reject(e.target.error);
    fileReader.readAsDataURL(input);
  });
}

function prepareImage(image) {
  const shouldResize = image.width > IMAGE_MAX_SIDE_SIZE || image.height > IMAGE_MAX_SIDE_SIZE;

  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext("2d");

  if (shouldResize) {
    const ratio = (image.width > image.height)
      ? IMAGE_MAX_SIDE_SIZE / image.width
      : IMAGE_MAX_SIDE_SIZE / image.height;

    canvas.width = Math.round(image.width * ratio);
    canvas.height = Math.round(image.height * ratio);

    // eslint-disable-next-line valid-typeof
    if (typeof ctx.imageSmoothingQuality !== undefined) {
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    } else {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      // todo step by step resize to smooth
    }
  } else {
    ctx.drawImage(image, 0, 0);
  }

  return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", 100));
}
