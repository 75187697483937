import React from 'react';
import routes from "../routes";
import {hitEvent, hits, logEvent, logProcessingsTimings, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {getProcessingGroups, getCreativesConfigs} from "../photolab/config";
import creativeGroups from "../photolab/config/groups";
import Processing from "../photolab/Processing"
import Creative from "../photolab/Creative";
import ErrorView from "../components/ErrorView";
import {resetNativeAdsCounter, showNativeAds} from "../utils/native-ads";
import clientStorage from "../utils/client-storage";
import processingManager from "../photolab/ProcessingManager";
import {debounce} from "../utils/etc";
import {generatePath} from "react-router";
import {webviewAnalyticsEvent} from "../utils/webview";

export default class ProcessingPage extends React.Component {

  state = {
    error: null,
  };

  processingTimerId = null;
  processingTimer = 0;

  componentDidMount() {
    document.addEventListener("visibilitychange", this.startProcessingTimer, false);
    processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);

    const locationState = this.props.location.state || {};
    const files = locationState.files;
    const maskFiles = locationState.maskFiles;
    const processing = processingManager.restore();

    if (processing) {
      this.context.showLoader(true);
      processingManager.start(processing);
    } else if (files) {
      this.context.showLoader(true);
      this.startProcessing(files, maskFiles);
    } else {
      this.props.history.replace(generatePath(routes.INDEX));
    }
  }

  componentWillUnmount() {
    this.stopProcessingTimer();

    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  stopProcessingTimer = () => {
    clearInterval(this.processingTimerId);
    document.removeEventListener("visibilitychange", this.startProcessingTimer, false);
  }

  startProcessingTimer = () => {
    clearInterval(this.processingTimerId);

    if (document.visibilityState !== "visible") {
      return;
    }

    this.processingTimerId = setInterval(() => {
      this.processingTimer++;

      if ((this.processingTimer * 1000) > window.appConfig.processings.timeout) {
        this.stopProcessingTimer();
        this.handleProcessingTimeout();
      }
    }, 1000);
  }

  startProcessing = (files, maskFiles) => {
    hitEvent(hits.PROCESSING_START);

    const groups = getProcessingGroups();
    const processing = new Processing();
    processing.setId(Date.now());
    processing.setGroups(groups);
    processing.setLanguage(window.clientConfig.lang);
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());

    files.forEach((file, index) => {
      processing.setFile(file, [index], index);
    });

    maskFiles && maskFiles.forEach((file, index) => {
      processing.setFile(file, [`m${index}`]);
    });

    // getCreativesConfigs()
    //   .filter((config) => config.group === creativeGroups.COMMON)
    //   .forEach((config) => {
    //     processing.addCreative(new Creative()
    //       .configureByConfig(config));
    //   });

    processingManager.start(processing);

    this.startCreatives(processing);
    this.startProcessingTimer();

    showNativeAds();
  };

  /** @param {Processing} processing */
  startCreatives = (processing) => {
    const freeAmount = window.clientConfig.features.creativesBatchSize * window.clientConfig.features.freeBatches;

    getCreativesConfigs().forEach((creativeConfig, index) => {
      const creative = new Creative().configureByConfig(creativeConfig);
      creative.setExtra(Creative.EXTRA_KEEP_PENDING, true);

      if (index >= freeAmount) {
        creative.setExtra(Creative.EXTRA_IS_NON_FREE, true);
      }

      processing.addCreative(creative);
    });

    const firstCreatives = processing.getNextCreatives(window.clientConfig.features.creativesBatchSize);
    firstCreatives.forEach((creative, index) => {
      creative.removeExtra(Creative.EXTRA_KEEP_PENDING);
      creative.setAsSelected(true);

      webviewAnalyticsEvent("generation_start", [
        "",
        "processing",
        clientStorage.getProcessedPhotosAmount(),
        "",
        "",
        index,
      ], {
        wt_couple_ai: {
          "face": creative.getExtra("t_face_id"),
          "gender": creative.getExtra("t_gender"),
          "canvas": creative.getExtra("t_canvas_id"),
          "mask": creative.getExtra("t_mask_id"),
          "tpl": creative.getExtra("t_prompt_id"),
        },
      });
    });

    processingManager.update();
  }

  /** @param {Processing} processing */
  handleProcessingChange = (processing) => debounce("ProcessingPage_handleProcessingChange", 100, () => {
    if (window.appConfig.isDebug) {
      console.info("ProcessingPage::handleProcessingChange", JSON.parse(processing.toJSON()));
    }

    const selectedAndStartedCreatives = processing.creatives.filter((c) => {
      return c.isSelected && c.group !== creativeGroups.COMMON;
    });

    if (selectedAndStartedCreatives.isEmpty()) {
      return;
    }

    const genderCreative = processing.creatives.find((c) => {
      return c.group === creativeGroups.COMMON && c.templateId === "gender";
    });

    if (genderCreative && genderCreative.isPending) {
      return;
    }

    const processedCreatives = selectedAndStartedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = selectedAndStartedCreatives.filter((c) => c.isFailed);

    if (processedCreatives.length > 0) {
      this.stopProcessingTimer();

      clientStorage.incrementProcessedPhotosAmount();
      if (!clientStorage.hasFirstProcessingProcessedAt()) {
        clientStorage.setFirstProcessingProcessedAt(Date.now());
      }

      const elapsedMs = Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT);

      hitEvent(hits.PROCESSING_PROCESSED);
      logEvent(userEvents.PROCESSING_PROCESSED, {elapsed_time_ms: elapsedMs});
      logProcessingsTimings(elapsedMs);

      this.props.history.replace({pathname: routes.RESULT});
    }
    else if (failedCreatives.length === selectedAndStartedCreatives.length) {
      this.stopProcessingTimer();

      const failedCreative = failedCreatives[0];

      hitEvent(hits.PROCESSING_FAILED);
      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT),
      });

      if (failedCreative.error && failedCreative.error.type === "photolab") {
        hitEvent(hits.PROCESSING_FAILED_BY_PHOTOLAB);
        resetNativeAdsCounter();
      }

      processingManager.clear();

      this.setState({
        error: failedCreative.error,
      }, this.context.hideLoader);
    }
  });

  handleProcessingTimeout = () => {
    processingManager.clear();

    this.setState({
      error: {
        type: "processing_timeout",
        code: 1,
        message: "timeout",
      },
    }, this.context.hideLoader);
  };

  handleErrorViewButtonClick = () => {
    webviewAnalyticsEvent("button_tapped", ["try_another_photo", "generation"]);
    this.props.history.replace(routes.UPLOAD);
  };

  render() {
    return (
      <React.Fragment>
        {this.state.error && <ErrorView
          error={this.state.error}
          onButtonClick={this.handleErrorViewButtonClick}
        />}
      </React.Fragment>
    );
  }
}

ProcessingPage.contextType = AppContext;
