import React from "react";
import i18n from "../i18n";
import {webviewAnalyticsEvent, webviewConsumable} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import AppContext from "../contexts/AppContext";
import {getInAppSku} from "../utils/inapp";

const defaultPrice = "$0.99";

export default class PurchaseBannerView extends React.Component {

  state = {
    sku: "nosku",
  };

  constructor(props) {
    super(props);
    this.state.sku = getInAppSku();
  }

  componentDidMount() {
    webviewAnalyticsEvent("inapp_banner_shown", [
      "couple_wt8_v1",
      "result",
      this.state.sku,
    ]);
  }

  handlePurchaseButtonClick = () => {
    webviewAnalyticsEvent("inapp_button_tapped", [
      "couple_wt8_v1",
      "result",
      this.state.sku,
    ]);

    clientStorage.setShouldRedirectToResult(true);

    webviewConsumable(this.state.sku);
  };

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    const skuData = this.context.skus[this.state.sku] || {};
    const price = (skuData.price || defaultPrice).replace(/\s+/, "\u00A0");

    return <div className="subscribe-banner">
      <h2>
        {/*<StarsIcon />*/}
        <span className="text-bold">Get 100+ <span className="text-yellow">premium</span> artworks</span>
      </h2>

      <p>Enjoy more variations with {price} one-time purchase</p>

      <button
        className="subscribe-btn"
        onClick={this.handlePurchaseButtonClick}>
        {i18n.t("button_purchase_with_price", {price})}
        <svg viewBox="0 0 25 8">
          <path d="M24.354 4.354a.5.5 0 0 0 0-.708L21.172.464a.5.5 0 1 0-.707.708L23.293 4l-2.828 2.828a.5.5 0 1 0 .707.708l3.182-3.182zM0 4.5h24v-1H0v1z"/>
        </svg>
      </button>
    </div>;
  }
}

PurchaseBannerView.contextType = AppContext;

function StarsIcon() {
  return <svg viewBox="0 0 32 32">
    <path d="M12.831 30.63a.6.6 0 0 1-.6-.6c0-8.581-1.972-10.555-10.553-10.555a.6.6 0 1 1 0-1.199c8.58 0 10.553-1.973 10.553-10.554a.6.6 0 0 1 1.2 0c0 8.581 1.972 10.554 10.553 10.554a.6.6 0 0 1 0 1.2c-8.58 0-10.553 1.973-10.553 10.554a.6.6 0 0 1-.6.6zM7.643 18.876a6.668 6.668 0 0 1 5.188 5.188 6.668 6.668 0 0 1 5.189-5.188 6.668 6.668 0 0 1-5.189-5.189 6.668 6.668 0 0 1-5.188 5.189zM30.322 6.976c-4.363 0-5.246-.882-5.246-5.246a.6.6 0 0 0-1.199 0c0 4.364-.883 5.246-5.247 5.246a.6.6 0 1 0 0 1.2c4.364 0 5.247.882 5.247 5.246a.6.6 0 1 0 1.2 0c0-4.364.882-5.246 5.245-5.246a.6.6 0 0 0 0-1.2z" fill="#F8D34F"/>
  </svg>;
}
