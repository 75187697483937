import React from "react";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";
import {webviewAnalyticsEvent, webviewInApp, webviewOpenBrowser, webviewRestore} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import {getSubscriptionSku} from "../utils/inapp";

const defaultPrice = "$7.99";

export default class SubscribeBannerView extends React.Component {

  state = {
    sku: "nosku",
  };

  constructor(props) {
    super(props);
    this.state.sku = getSubscriptionSku();
  }

  componentDidMount() {
    webviewAnalyticsEvent("subscribe_banner_shown", [
      "couple_wt8_v1",
      "result",
      this.state.sku,
    ]);
  }

  componentWillUnmount() {

  }

  handleSubscribeButtonClick = () => {
    webviewAnalyticsEvent("subscribe_button_tapped", [
      "couple_wt8_v1",
      "result",
      this.state.sku,
    ]);

    clientStorage.setShouldRedirectToResult(true);

    webviewInApp(this.state.sku);
  };

  handleTermsOfUseButtonClick = () => {
    webviewOpenBrowser("https://pho.to/terms.html", window.clientConfig.isWebviewIOS ? "safari" : undefined);
  };

  handlePrivacyPolicyButtonClick = () => {
    webviewOpenBrowser("https://pho.to/privacy.html", window.clientConfig.isWebviewIOS ? "safari" : undefined);
  };

  handleRestoreButtonClick = () => {
    webviewRestore();
  };

  render() {
    const skuData = this.context.skus[this.state.sku] || {};
    const price = (skuData.price || defaultPrice).replace(/\s+/, "\u00A0");

    return <div className="subscribe-banner">
      <h2>
        {/*<StarsIcon />*/}
        <span className="text-bold">Get <span className="text-yellow">unlimited</span> access to <span className="text-yellow">premium</span> artworks.</span>
      </h2>

      <p>
        Enjoy 3 days for FREE, then {price}/week
        {/*<StarIcon />*/}
      </p>

      <button
        className="subscribe-btn"
        onClick={this.handleSubscribeButtonClick}>
        {i18n.t("button_subscribe_with_price", {price})}
        <svg viewBox="0 0 25 8">
          <path d="M24.354 4.354a.5.5 0 0 0 0-.708L21.172.464a.5.5 0 1 0-.707.708L23.293 4l-2.828 2.828a.5.5 0 1 0 .707.708l3.182-3.182zM0 4.5h24v-1H0v1z"/>
        </svg>
      </button>

      <p className="price-text">&nbsp;</p>
      {/*<p className="price-text" dangerouslySetInnerHTML={{__html: i18n.t("button_subscribe_sub", {price})}} />*/}
      <p className="subscribe-text">{i18n.t("subscribe_subscription_terms")}</p>

      <div className="subscribe-footer">
        <button onClick={this.handleTermsOfUseButtonClick}>Terms of Use</button>
        <button onClick={this.handlePrivacyPolicyButtonClick}>Privacy Policy</button>
        <button onClick={this.handleRestoreButtonClick}>Restore</button>
      </div>
    </div>;
  }
}

SubscribeBannerView.contextType = AppContext;

function StarsIcon () {
  return <svg viewBox="0 0 32 32">
    <path d="M12.831 30.63a.6.6 0 0 1-.6-.6c0-8.581-1.972-10.555-10.553-10.555a.6.6 0 1 1 0-1.199c8.58 0 10.553-1.973 10.553-10.554a.6.6 0 0 1 1.2 0c0 8.581 1.972 10.554 10.553 10.554a.6.6 0 0 1 0 1.2c-8.58 0-10.553 1.973-10.553 10.554a.6.6 0 0 1-.6.6zM7.643 18.876a6.668 6.668 0 0 1 5.188 5.188 6.668 6.668 0 0 1 5.189-5.188 6.668 6.668 0 0 1-5.189-5.189 6.668 6.668 0 0 1-5.188 5.189zM30.322 6.976c-4.363 0-5.246-.882-5.246-5.246a.6.6 0 0 0-1.199 0c0 4.364-.883 5.246-5.247 5.246a.6.6 0 1 0 0 1.2c4.364 0 5.247.882 5.247 5.246a.6.6 0 1 0 1.2 0c0-4.364.882-5.246 5.245-5.246a.6.6 0 0 0 0-1.2z" fill="#F8D34F"/>
  </svg>;
}

function StarIcon () {
  return <svg viewBox="0 0 32 32">
    <g clipPath="url(#nzh1vb2zba)">
        <path d="M16.06 2.124C16.06 12.531 19.529 16 29.936 16 19.529 16 16.06 19.47 16.06 29.876 16.06 19.47 12.59 16 2.184 16 12.59 16 16.06 12.531 16.06 2.124zm0-1.2a1.2 1.2 0 0 0-1.2 1.2c0 9.714-2.962 12.676-12.676 12.676a1.2 1.2 0 0 0 0 2.4c9.714 0 12.676 2.962 12.676 12.676a1.2 1.2 0 0 0 2.4 0c0-9.714 2.961-12.676 12.676-12.676a1.2 1.2 0 1 0 0-2.4c-9.715 0-12.676-2.962-12.676-12.676a1.2 1.2 0 0 0-1.2-1.2z" fill="#F8D34F"/>
    </g>
    <defs>
        <clipPath id="nzh1vb2zba">
            <path fill="#fff" d="M0 0h32v32H0z"/>
        </clipPath>
    </defs>
  </svg>;
}
